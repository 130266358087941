import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useLocalJsonForm } from "gatsby-tinacms-json"

import SEO from "../components/seo"
import { PhoneCarousel } from "../blocks/PhoneCarousel"
import { PlainSelector } from "../blocks/PlainSelector"
import { SolidBoi } from "../blocks/SolidBoi"
import { PageForm, Page } from "../templates/page"

const AppStore = require("../images/download-maxrewards-app-store.svg")
const PlayStore = require("../images/download-maxrewards-play-store.svg")

const LocalDealsPage = ({ data }) => {
  const [page]: any = useLocalJsonForm(data?.dataJson, PageForm)
  const { works, benefits, cta, cta_link, incentive } = page

  return (
    <Page
      title={page?.title || ""}
      description={page?.description || ""}
      useCustomHeader
    >
      <section
        className="uk-section uk-section-primary gradient"
        data-uk-scrollspy="target: h1, h2, p, .uk-position-relative, .height-xsmall, .uk-card; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-container uk-text-center">
          <div className="uk-alert-danger uk-text-center uk-padding-small">
            Local Deals has been decommissioned for now.
          </div>
          <div className="uk-child-width-1-1 uk-flex-middle" data-uk-grid>
            <div className="uk-text-left@m uk-width-1-3@m uk-margin-large-top uk-margin-remove-top@s">
              <h1 className="uk-text-left@m">
                We'll create offers that maximize your revenue
              </h1>
            </div>

            <div className="uk-width-expand@m uk-text-center">
              <div className="uk-position-relative">
                <div
                  className="shape5 uk-position-top-right"
                  data-uk-parallax="y: 100; easing: 0"
                ></div>
                <div
                  className="shape6 uk-position-bottom-left"
                  data-uk-parallax="y: -200; easing: 0"
                ></div>
                <div className="uk-padding-large uk-position-relative">
                  <img
                    className="uk-width-1-1 uk-box-shadow-large"
                    src="/restaurateur-hero.png"
                    alt="Restaurateur"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="stripe"></div>
        <div className="uk-container uk-position-relative uk-margin-large-top">
          <div
            className="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
            data-uk-grid
          >
            <div className="uk-width-1-1">
              <h2 className="uk-text-center">How It Works</h2>
            </div>
            {works &&
              works.map((work) => (
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <div className="uk-margin-auto uk-width-3-4">
                      <img src={work?.img || ""} />
                    </div>

                    <h3 className="title">{work?.title}</h3>

                    <h5>{work?.text}</h5>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <section
        className="uk-section uk-section-muted uk-padding-remove-top"
        data-uk-scrollspy="target: h2, img, h3, h5; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-container uk-text-center">
          <h2 className="uk-text-center uk-margin-large-bottom">
            The easiest way to drive more sales
          </h2>
          <div
            className="uk-grid-divider uk-grid-large uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center"
            data-uk-grid
          >
            {benefits &&
              benefits.map((benefit) => (
                <div>
                  <img
                    className="uk-width-small uk-width-1-2@s"
                    src={benefit?.img || ""}
                    alt="{ benefit?.title }"
                  />
                  <h3 className="title">{benefit?.title}</h3>
                  <h5>{benefit?.text}</h5>
                </div>
              ))}
          </div>
        </div>
      </section>

      <div id="modal" className="uk-modal-full" data-uk-modal>
        <div className="uk-modal-dialog">
          <button
            className="uk-modal-close-full uk-close-large"
            type="button"
            data-uk-close
          ></button>
          <iframe
            id="typeform-full"
            width="100%"
            height="100%"
            data-frameborder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            src="https://maxrewards.typeform.com/to/nUGxGX"
            data-uk-height-viewport
          ></iframe>
          <script
            type="text/javascript"
            src="https://embed.typeform.com/embed.js"
          ></script>
        </div>
      </div>
    </Page>
  )
}

export const query = graphql`
  query localDealsRestaurateurQuery {
    dataJson(
      fileRelativePath: { eq: "/src/data/local-deals-restaurateur.json" }
    ) {
      title
      description
      works {
        img
        title
        text
      }
      benefits {
        img
        title
        text
      }
      cta
      cta_link
      incentive
      blocks {
        _template
        title
        linkTitle
        linkUrl
        id
        data {
          title
          description
          iconColor
          img
          text
          image
        }
        reverse
        title
        use_dark
        shapes
        use_different_header
        other_section_classes {
          remove_padding_top
          remove_padding_bot
          extra_padding_bot
        }
        testimonial {
          title
          text
          bg_decoration
        }
        use_test
        use_custom_footer
      }

      # TinaCMS fields
      rawJson
      fileRelativePath
    }
  }
`

export default LocalDealsPage
